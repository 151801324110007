import { Headline } from '@dfds-ui/react-components'
import { graphql } from 'gatsby'

import { gridMap } from '../../utils'
import { FlexCard } from '../Card'
import { AspectImage } from '../Image'
import { Markdown } from '../Markdown'
import { EntryLink } from './../EntryLink'

const PromoBlockDefaultProps = {
  image: null,
}

const PromoBlock = ({
  backgroundColor,
  blockWidth,
  promoContent: { image, title, text, link } = PromoBlockDefaultProps,
}) => {
  const background =
    backgroundColor === 'Blue' || backgroundColor === 'Transparent'

  return (
    <FlexCard
      dark={background}
      width={blockWidth ? gridMap[blockWidth] : gridMap['full-width']}
      media={image && <AspectImage {...image} aspectRatio={'2/1'} />}
      action={<EntryLink {...link} />}
    >
      <Headline as={'h3'} styledAs={'h2'}>
        {title}
      </Headline>
      <Markdown source={text} />
    </FlexCard>
  )
}

export const query = graphql`
  fragment PromoBlock on contentful_PromoBlock {
    __typename
    sys {
      id
    }
    backgroundColor
    blockWidth
    promoContent {
      ...PromoContent
    }
  }

  fragment PromoContent on contentful_PromoContent {
    text
    title
    image {
      ...Asset
    }
    link {
      ...ItemLink
    }
  }
`

export default PromoBlock
